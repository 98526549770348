import React from "react"

const AppointletButton = () => {
  return (
    <a
      className="btn btn-success text-dark text-uppercase"
      href="https://fachhandwerk360.appointlet.com/"
      target="_blank"
      rel="noreferrer"
    >
      Jetzt kostenloses <br />
      Erstgespräch vereinbaren
    </a>
  )
}

export default AppointletButton
